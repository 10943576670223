import React, { type PropsWithChildren, type ReactNode } from 'react';

import clsx from 'clsx';

import { DarkModeSwitcher } from '@quick/components/DarkModeSwitcher';

import { NoSSR } from '@/components/core/NoSSR';
import styles from '@/styles/utils.module.css';

export default function BaseEmptyLayout({
  children,
  lang,
}: PropsWithChildren<{ lang?: ReactNode }>) {
  return (
    <div
      className={clsx(
        `relative h-full w-full overflow-y-auto transition-colors dark:bg-gray-800
        dark:text-gray-300`,
        styles.top_safe
      )}
      id="MainContainer"
    >
      <div className="fixed right-0 z-10 p-2 text-2xl">
        <div className="flex items-center gap-2">
          {lang}
          <NoSSR>
            <DarkModeSwitcher />
          </NoSSR>
        </div>
      </div>
      {children}
    </div>
  );
}

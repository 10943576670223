import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { ReactComponent as NotFound } from '@/assets/img404.svg';
import EmptyLayout from '@/components/core/EmptyLayout/BaseEmptyLayout';
import { getI18nProps } from '@/lib/getStatic';
import i18nextConfig from '@/next-i18next.config';

E404.Layout = EmptyLayout;
export async function getStaticProps() {
  const lang = i18nextConfig.i18n.defaultLocale;
  return {
    props: {
      ...(await getI18nProps({ params: { lang } }, [])),
    },
  };
}
function E404() {
  const router = useRouter();

  useEffect(() => {
    if (window) {
      const t = setTimeout(() => {
        if (document.referrer.split('/')[2] != location.hostname) {
          router.replace('/');
        } else {
          router.back();
        }
      }, 5_000);
      return () => clearTimeout(t);
    }
  }, [router.isReady, router]);
  return (
    <div className="flex flex-col items-center gap-4 p-4">
      <NotFound className="max-w-sm" />
      <div className="flex flex-col gap-2">
        <span className="text-center font-semibold text-gray-400">
          The page you are looking for does not exist or is not available
        </span>
        <span className="text-center font-semibold text-gray-400">
          La página que estás buscando no existe o no está disponible
        </span>
      </div>
      <div
        className="border-brand h-16 w-16 animate-spin rounded-full border-4 border-dashed
          border-t-transparent"
      />
    </div>
  );
}

export default E404;
